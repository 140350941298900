// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("controllers")

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "alpinejs"
import 'tailwindcss/dist/tailwind.css'
import 'css/application'

import "@stimulus/polyfills"

const Trix = require("trix")
require("@rails/actiontext")

const images = require.context('../images', true)

Trix.config.blockAttributes.heading1.tagName = 'h3'

Rails.start()
ActiveStorage.start()

// const getHexColor = function(color) {
//   var hexValues, rgbValues;
//   if (!color) {
//     return "";
//   }
//   if (/^#/.test(color)) {
//     return color;
//   }
//   rgbValues = getRGBValues(color);
//   hexValues = rgbValues.map(numberToHex);
//   return "#" + hexValues.join("");
// };
//
// const numberToHex = function(number) {
//   return ("0" + (number.toString(16))).slice(-2).toUpperCase();
// };
//
// const getRGBValues = function(color) {
//   var b, canvas, context, data, g, match, r, _i;
//   if (match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i)) {
//     _i = match.length - 3, r = match[_i++], g = match[_i++], b = match[_i++];
//   } else {
//     canvas = document.createElement("canvas");
//     canvas.height = canvas.width = 1;
//     context = canvas.getContext("2d");
//     context.fillStyle = color;
//     context.fillRect(0, 0, 1, 1);
//     data = context.getImageData(0, 0, 1, 1).data;
//     r = data[0], g = data[1], b = data[2];
//   }
//   return [r, g, b].map(Number);
// };
//
// const createColorParser = function() {
//   return function(element) {
//     var color, styleColor;
//     while (element && element.tagName !== "TRIX-EDITOR") {
//       if (styleColor = element.style[this.styleProperty]) {
//         if (color = getHexColor(styleColor)) {
//           return color;
//         }
//       }
//       element = element.parentElement;
//     }
//   };
// };

Trix.config.textAttributes.primaryColor = {
  inheritable: true,
  style: {color: 'rgb(5, 150, 105)'},
  parser:  (element) => {
    return element.style.color === "rgb(5, 150, 105)"
  }
};

addEventListener("trix-initialize", function(event) {
  let buttonHTML = '<button type="button" class="trix-button trix-button--icon trix-button--icon-text-color" data-trix-attribute="primaryColor" data-trix-key="o" title="Primary Color" tabindex="-1">Text Color</button>';
  event.target.toolbarElement.querySelector(".trix-button-group--text-tools").insertAdjacentHTML("beforeend", buttonHTML)
})