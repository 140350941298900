import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['link'];

  copy(event) {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = this.linkTarget.href;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
}